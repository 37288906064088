import React from 'react'
import LandingPageAdvertorial from '../../../templates/landing-page-advertorial'
import LandingPage from '../../../templates/landing-page'

import content from '../../../data/pages/lp/article/meet-le-original/content.json'

const AdvertorialMeetLEOriginalLP = () => (
  <LandingPage content={content} type='advertorial' />
)

export default AdvertorialMeetLEOriginalLP
